import MobileNav from '../MobileNav/MobileNav';
import PcNav from '../PcNav/PcNav';
import './Nav.sass'

const Nav = () => {
    return ( 
        <main className="Nav">
            <div className="parentt">
                <div className="mobile">
                    <MobileNav />
                </div>
                <div className="pc">
                    <PcNav />
                </div>
            </div>
        </main>
     );
}
 
export default Nav;