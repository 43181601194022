import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from './Pages/Home'

const App = () => {
  return ( 
    <main className="App">
        <Routes>
          <Route exact path="/" element={<Home />} />
        </Routes>
    </main>
   );
}
export default App;
