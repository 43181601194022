import './PcNav.sass'

const PcNav = () => {
    return ( 
        <main className="PcNav">
            <nav>
                <div className='logo'>
                    <div className="icon"></div>
                    <span>BRIXTON</span>
                </div>
                <div className="links">
                    <ul>
                        <li>Home</li>
                        <li>Investments</li>
                        <li>Education</li>
                        <li>Plans</li>
                        <li>Contact</li>
                        <li>About</li>
                        <li>Login / Signup</li>
                    </ul>
                </div>
            </nav>
        </main>
     );
}
 
export default PcNav;