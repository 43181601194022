import './Plans.sass'
import { Plan } from '../../Data/DataInfo'
import { ArrowRightAlt } from '@mui/icons-material';

const Plans = () => {
    return ( 
        <main className="Plans">
            <div className="parent">
                <div className='cards'>
                    {Plan.map((plan, i) => (
                        <div className='card'>
                            <span className='img'>{plan.img}</span>
                            <span className='plan'>{plan.plan}</span>
                            <span className='percentage'>{plan.percentage}</span>
                            <div className='wrapper'>
                                <div className='wrapper1'>
                                    <span>Minimum Investment: {plan.minimum}</span>
                                </div>
                                <div className='wrapper1'>
                                    <span>Maximum Investment: {plan.maximum}</span>
                                </div>
                                <div className='wrapper1'>
                                    <span>Referral Commission: {plan.commission}</span>
                                </div>
                            </div>
                            <p className="btn">
                                <span>Check Out</span> <ArrowRightAlt />
                            </p>

                        </div>
                    ))}
                        
                </div>
            </div>
        </main>
     );
}
 
export default Plans;