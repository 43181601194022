import './Main.sass'
import Nav from '../Nav/Nav';
import Chain from '../../Images/chain.png'
import Fade from 'react-reveal/Fade';
const Main = () => {
    return ( 
        <main className="Main">
            <Fade duration='3000'>
                <div className="parent">
                    <Nav />
                    <div className="main">
                        <div className="left">
                            <p>BUILD ON WEB3. POWERED BY YOU.</p>
                            <p>The Future of <span>Investment</span> Is Here</p>
                            <p>Invest on any tokens with a protocol trusted with billions for its performance and reliability.</p>
                            <div>
                                <p>GET STARTED</p>
                                <p>LEARN MORE</p>
                            </div>
                        </div>
                        <div className="right">
                            <img src={Chain} alt="" className='chain'/>
                            </div>
                        </div>
                    </div>
                </Fade>
        </main>
     );
}
 
export default Main;