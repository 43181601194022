import { Menu, Clear } from '@mui/icons-material';
import { useEffect } from 'react';
import { useRef, useState } from 'react';
import './MobileNav.sass'

const MobileNav = () => {
    const [opened, setOpened] = useState(false)
    const menuRef = useRef()

    const showmenu = () => {
        setOpened(!opened)
    }
    useEffect(
        () => {
            if(opened){
                menuRef.current.style.transform='translateX(0)'
            }
            else{
                menuRef.current.style.transform='translateX(100vw)'
            }
        },[opened]
    )
    return ( 
        <main className="MobileNav">
            <div className="parent">
                <div className="top">
                    <p className='logo'>BRIXTON</p>
                    {opened
                    ?
                        <Clear className='menuicon' onClick={showmenu}/>
                    :
                        <Menu className='menuicon' onClick={showmenu}/>
                    }
                </div>
                <div className="bottom" ref={menuRef}>
                    <ul>
                        <li>Home</li>
                        <li>Investments</li>
                        <li>Education</li>
                        <li>Plans</li>
                        <li>About</li>
                        <li>Contact</li>
                        <li>Login / Signup</li>
                    </ul>
                </div>
            </div>
            <div className="push"></div>
        </main>
     );
}
 
export default MobileNav;