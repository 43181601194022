import './Testimonial.sass'
import { BsChat } from 'react-icons/bs'
import { Testimonials } from '../../Data/DataInfo';
const Testimonial = () => {

    return ( 
        <main className="Testimonial">
            <div className="parent">
                <div className="cards">
                    {Testimonials.map((Testimonial, i)=>(
                        <div className="card">
                            <p className="text">{Testimonial.text}</p>
                            <div className="wrapper">
                                <div className="cont">
                                    <div className="circle">
                                        <img src={Testimonial.img} alt="" />
                                    </div>
                                    <div className="cover">
                                        <p className="name">{Testimonial.name}</p>
                                        <p className="post">{Testimonial.post}</p>
                                    </div>
                                </div>
                                <BsChat />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </main>
     );
}
 
export default Testimonial;